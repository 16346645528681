import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavService } from '../../../../services/nav.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { AppNavItemComponent } from './nav-item/nav-item.component';
import { User } from 'src/app/models/user';
import {AuthenticationService} from "../../../../services/authentication.service";
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    RouterModule,
    AppNavItemComponent, CommonModule
  ],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  navopt = this.navService.showClass;
  user: User | undefined;
  constructor(
    public navService: NavService,
    public authService: AuthenticationService,
    private router: Router,
    )
  {this.user = authService.userValue;}

  ngOnInit(): void {}

  async logout() {
    this.authService.deleteUser();
    await this.router.navigateByUrl('login');
  }
}
