<mat-card class="chart-card" style="height: 470px !important;">
  <mat-progress-bar *ngIf="!loaded.value" mode="indeterminate"></mat-progress-bar>
  <mat-card-header>
    <div class="card-header-container">
      <mat-card-title *ngIf="title.length">
        {{title}}<br>      

        <button (click)="openDialog()" class="btn btn-outline-primary lgFilterButton">
          <div class="filterText">Filtri</div>
          <mat-icon class="icon-display" fontIcon="filter_alt">filter_alt</mat-icon>
        </button>
        
      </mat-card-title>
      <mat-button-toggle-group name="fontStyle" #group="matButtonToggleGroup" aria-label="Font Style" [value]="type" (change)="setType(group.value)">
        <mat-button-toggle value="plant" *ngIf="userIsNet()">Punto vendita</mat-button-toggle>
        <mat-button-toggle value="product">Prodotto</mat-button-toggle>
      </mat-button-toggle-group>

      <button (click)="openDialog()" class="btn btn-outline-primary mdFilterButton">
        <div class="filterText">Filtri</div>
        <mat-icon class="icon-display" fontIcon="filter_alt">filter_alt</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="sales-line-card-header-filter-container {{userIsNet() ? '' : 'justify-content-end'}}">
    </div>

    <div id="visitor-chart" class="m-t-40 p-t-30 p-b-20 d-flex flex-column">
      <apx-chart
        [series]="SalesChartDonutChartOptions.series"
        [chart]="SalesChartDonutChartOptions.chart"
        [labels]="SalesChartDonutChartOptions.labels"
        [responsive]="SalesChartDonutChartOptions.responsive"
        [tooltip]="SalesChartDonutChartOptions.tooltip"
        [legend]="SalesChartDonutChartOptions.legend"
        [colors]="SalesChartDonutChartOptions.colors"
        [dataLabels]="SalesChartDonutChartOptions.dataLabels"
        [plotOptions]="SalesChartDonutChartOptions.plotOptions"
        [stroke]="SalesChartDonutChartOptions.stroke"
        [noData]="SalesChartDonutChartOptions.noData"
      >
      </apx-chart>
    </div>
  </mat-card-content>
</mat-card>
