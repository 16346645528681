<div class="dialogStyle">
  <div class="cursor-pointer w-100 text-right" (click)="onNoClick()">
    <i-tabler name="x"></i-tabler>
  </div>
  <h1 mat-dialog-title>Filtri</h1>
  <div mat-dialog-content>
    <mat-form-field [style.width.%]="100" class="sales-area" *ngIf="showPlants">
      <mat-label>Selezione impianti</mat-label>
      <mat-select 
        [formControl]="plantCtrl"
        name="plant"
        #singleSelect
        ngDefaultControl
        (selectionChange)="applyFilters('plant', $event.value)" 
        [(ngModel)]="plantForm"
        [value]="data.period"
        multiple>
        <mat-option>
          <ngx-mat-select-search 
              placeholderLabel="Cerca..." 
              [formControl]="plantFilterCtrl"
              noEntriesFoundLabel="Nessun risultato.">
          </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let plant of filteredPlants | async" [value]="plant.id">
          {{plant.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field [style.width.%]="100" class="sales-area">
      <mat-label>Periodo</mat-label>
      <mat-select (selectionChange)="applyFilters('period', $event.value)" [value]="data.period">
        <mat-option *ngFor="let product of data.periodList" [value]="product.id">
          {{product.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="showDatePicker || data.period === 6">
      <mat-form-field class="w-100">
        <mat-label>Data inizio</mat-label>
        <input matInput [matDatepicker]="pickerFrom" [value]="data.dates.from" (dateChange)="applyFilters('from',$event.value)">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label>Data fine</mat-label>
        <input matInput [matDatepicker]="pickerTo" [value]="data.dates.to" (dateChange)="applyFilters('to',$event.value)">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions class="p-12 d-block text-right">
    <button [mat-dialog-close]="reset" class="btn btn-warning m-r-10">Azzera filtri</button>
    <button [mat-dialog-close]="results" class="btn btn-success" cdkFocusInitial>Applica filtri</button>
  </div>
</div>