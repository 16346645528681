import {MatPaginatorIntl} from "@angular/material/paginator";
import {Injectable} from "@angular/core";

@Injectable()
export class CustomPaginator extends MatPaginatorIntl{

  constructor() {
    super();

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {

    this.itemsPerPageLabel = "Elementi per pagina";
    this.firstPageLabel = 'Prima pagina';
    this.lastPageLabel = 'Ultima pagina';
    this.nextPageLabel = "Successiva";
    this.previousPageLabel = "Precedente";
    this.changes.next();

  }

}
