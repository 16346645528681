import { Routes } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { AuthGuard } from "./guards/auth.guard";
import { Role } from "./enum/role";
import { PageNotFoundComponent } from "./error/page-not-found/page-not-found.component";
import { PermissionGuard } from "./guards/permission.guard";

export const AppRoutes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard, PermissionGuard],
    children: [
      {
        path: "",
        redirectTo: "/dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        data: { role: [Role.NET, Role.PLANT] },
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "transactions",
        data: { role: [Role.PLANT] },
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (m) => m.TransactionsModule
          ),
      },
      {
        path: "closures",
        data: { role: [Role.PLANT, Role.NET] },
        loadChildren: () =>
          import("./closures/closures.module").then((m) => m.ClosuresModule),
      },
      // {
      //   path: "business-intelligence",
      //   data: { role: [Role.NET] },
      //   loadChildren: () =>
      //     import("./business-intelligence/business-intelligence.module").then(
      //       (m) => m.BusinessIntelligenceModule
      //     ),
      // },
    ],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];
