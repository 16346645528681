import {Component, Inject, Input, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Formatter} from "../../../utils/formatter";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ClosuresService} from "../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {ClosureDetail} from "../../../../models/closures";

@Component({
  template: ''
})
export class AbstractClosureDetailsComponent implements OnInit {

  @Input()
  closureId = 0;

  @Input()
  title = '';

  @Input()
  displayedColumns: string[] | undefined;

  @Input()
  showTotals = false;

  isModalView = false;

  loaded = new BehaviorSubject<boolean>(false);

  formatAmount = Formatter.formatAmount;
  formatQuantity = Formatter.formatQuantity;
  formatPrice = Formatter.formatPrice;

  protected filter = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    protected closureService: ClosuresService,
    protected logger: NGXLogger
  ) {

    // Pass parameter from a modal
    if (modalData) {

      this.isModalView = true;

      if (modalData.closureId) {
        this.closureId = modalData.closureId;
      }

      if (modalData.title) {
        this.title = modalData.title;
      }

      if (modalData.showTotals) {
        this.showTotals = modalData.title;
      }

    }

  }

  ngOnInit(): void {
  }

  protected fetchData(callback: Function) {
    this.closureService.getOne(this.closureId, this.filter)
      .subscribe((res: ClosureDetail) => {
        this.loaded.next(true);
        this.logger.debug(res);
        callback(res);
      })
  }

  isDiverted(amount: number) {
    return amount < 0;
  }

  totalQuantity(quantity: number) {
    return Formatter.formatQuantity(quantity, {id: 1, name: ''});
  }

  totalAmount(amount: number) {
    return Formatter.formatAmount(amount);
  }

}
