import { NavItem } from './nav-item/nav-item';
import {Role} from "../../../../enum/role";

export const navItems: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'layout-dashboard',
    route: '/dashboard',
    roles: [Role.NET, Role.PLANT]
  },
  {
    displayName: 'Transazioni',
    iconName: 'coin-euro',
    route: '/transactions',
    roles: [Role.NET, Role.PLANT]
  },
  {
    displayName: 'Chiusure',
    iconName: 'calendar-check',
    route: '/closures',
    roles: [Role.NET, Role.PLANT]
  },
  // {
    // displayName: 'Business intelligence',
    // iconName: 'chart-histogram',
    // route: 'business-intelligence',
    // roles: [Role.NET],
    // children: [
    //   {
    //     displayName: 'Analisi base',
    //     iconName: 'point',
    //     route: 'business-intelligence/analisi',
    //     roles: [Role.NET]
    //   },
    //   {
    //     displayName: 'Analisi PRO',
    //     iconName: 'point',
    //     route: 'business-intelligence/pro',
    //     roles: [Role.NET]
    //   },
    // ],
  // },

];
