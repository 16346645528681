import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private _snackBar: MatSnackBar) { }

  handleError(error: any): void {
    //TODO log error
    console.error(error);

    // next show a friendly message
    this.errorNotification('Spiacenti, si è verificato un errore', 'Ok');
  }

  errorNotification(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['error-snackbar']
    });
  }

}
