export enum Role {
  UNKNOWN,
  PLANT = 2,
  NET = 3
}

export const RoleRouteIdentifier = {
  0: 'sconosciuto',
  2: 'gestore',
  3: 'admin'
}

export const getRoleFromRouterIdentifier = (role: string): Role => {
  switch (role) {
    case 'gestore':
      return Role.PLANT;
    case 'admin':
      return Role.NET;
    default:
      return Role.UNKNOWN;
  }
}
