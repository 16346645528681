import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";
import {ErrorHandlerService} from "../services/error-handler.service";
import {Location} from "@angular/common";
import {NGXLogger} from "ngx-logger";
import {Role} from "../enum/role";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  constructor(
    private authService: AuthenticationService,
    private location: Location,
    private errorHandler: ErrorHandlerService,
    private logger: NGXLogger
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (route.data.role && !route.data.role.includes(this.authService.userValue ? this.authService.userValue.level : Role.UNKNOWN)) {

      this.logger.debug(route.data.role);
      this.logger.debug(this.authService.userValue ? this.authService.userValue.level : Role.UNKNOWN);

      this.errorHandler.errorNotification('Non sei autorizzato ad effettuare questa operazione', 'Ok');
      this.location.back();
      return false;
    }

    return true;
  }

}
