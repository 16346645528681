import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map} from "rxjs";
import {ClosureDetail, ClosureNetListResponse, ClosurePlantListResponse} from "../models/closures";
import {Formatter} from "../shared/utils/formatter";
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ClosuresService {

  protected endpoint: string;

  constructor(
    protected http: HttpClient,
    protected logger: NGXLogger
  ) {
    this.endpoint = `${environment.api}/closures`;
  }

  netList(params: any) {

    let httpParams = JSON.parse(JSON.stringify(params));

    if (params.from) {
      httpParams.from = Formatter.formatFilterDate(params.from);
    }

    if (params.to) {
      httpParams.to = Formatter.formatFilterDate(params.to, true);
    }

    if (params.plant){
      httpParams.plant = JSON.stringify(params.plant);
    }

    return this.http.get(`${this.endpoint}`, { 
      params: httpParams 
    }).pipe(
      map((res) => <ClosureNetListResponse>res)
    );
  }

  plantList(plantId: number, params: any = {}) {

    let p = JSON.parse(JSON.stringify(params));

    if (params.from) {
      p.from = Formatter.formatFilterDate(params.from);
    }

    if (params.to) {
      p.to = Formatter.formatFilterDate(params.to, true);
    }

    return this.http.get(`${this.endpoint}/plant/${plantId}`, {
      params: p
    }).pipe(
      map((res) => <ClosurePlantListResponse>res)
    );
  }

  getOne(id: number, params: any = {}) {
    return this.http.get(`${this.endpoint}/${id}`, {
      params: params
    }).pipe(
      map((res) => <ClosureDetail>res)
    )
  }

  add(body: {
    type: string,
    plant_id: number,
    date: string,
    close: boolean | undefined
  }) {
    return this.http.post(`${this.endpoint}`, body);
  }

}
