import {Component, Inject } from '@angular/core';
import {ClosuresService} from "../../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ClosureDetail} from "../../../../../models/closures";
import {Sale} from "../../../../../models/sale";
import {ClosureDetailsType} from "../../../../../enum/closure-details-type";
import {AbstractClosureDetailsComponent} from "../abstract-closure-details-component";

@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.css']
})
export class SalesListComponent extends AbstractClosureDetailsComponent{

  displayedColumns = ['service', 'product', 'quantity', 'price', 'amount'];

  data: Sale[] = [];

  totalAmountValue: number;
  totalQuantityValue: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    protected closureService: ClosuresService,
    protected logger: NGXLogger
  ) {
    super(modalData,closureService, logger);
    this.totalAmountValue = 0;
    this.totalQuantityValue = 0;
  }

  ngOnInit() {

    this.filter = {
      type: ClosureDetailsType.Sales
    };

    this.fetchData((res: ClosureDetail) => {
      this.data = res.sales;
      this.data.forEach((sale) => {
        this.totalAmountValue += sale.amount;
        this.totalQuantityValue += sale.quantity;
      });
    })
  }

}
