<mat-card>

  <mat-card-header *ngIf="title.length">
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="!loaded.value">
    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
  </mat-card-content>

  <mat-card-content *ngIf="loaded.value">
    <div class="table-responsive">
      <table mat-table [dataSource]="data">

        <!-- Pump Column -->
        <ng-container matColumnDef="pump">
          <th mat-header-cell *matHeaderCellDef> N.Lato</th>
          <td mat-cell *matCellDef="let element">
            {{element.dispenser.pump}}
          </td>
        </ng-container>

        <!-- Pistol Column -->
        <ng-container matColumnDef="pistol">
          <th mat-header-cell *matHeaderCellDef> N.Erogatore</th>
          <td mat-cell *matCellDef="let element">
            {{element.dispenser.pistol}}
          </td>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef> Prodotto</th>
          <td mat-cell *matCellDef="let element">
            {{element.product.name}}
          </td>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Numero iniziale</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatNumber(element.numbers.start)}}
          </td>
        </ng-container>

        <!-- End Column -->
        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Numero finale</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatNumber(element.numbers.end)}}
          </td>
        </ng-container>

        <!-- Diff Column -->
        <ng-container matColumnDef="diff">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Differenza</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatNumber(element.numbers.diff)}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="isModalView" class="mt-4 text-center">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Chiudi</button>
</div>

