<span *ngIf="noData; else detailsData" class="text-danger p-4">
  Nessun dettaglio disponibile
</span>

<ng-template #detailsData>
  <div class="table-rasponsive">
    <table mat-table [dataSource]="details" class="w-100">

      <!-- Payment Column -->
      <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef> Dettaglio pagamento</th>
        <td mat-cell *matCellDef="let element">{{element.payment}}</td>
        <td class="{{showTotals ? '' : 'd-none'}} text-start" mat-footer-cell *matFooterCellDef><strong>Totale</strong></td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Importo (&euro;)</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          {{formatAmount(element.amount)}}
        </td>
        <td class="{{showTotals ? '' : 'd-none'}} text-center" mat-footer-cell *matFooterCellDef><strong>{{getTotalAmount()}}</strong></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
      <tr class="{{showTotals ? '' : 'd-none'}}" mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</ng-template>
