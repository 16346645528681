import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ClosuresService} from "../../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {AbstractClosureDetailsComponent} from "../abstract-closure-details-component";
import {ClosureDetailsType} from "../../../../../enum/closure-details-type";
import {ClosureDetail} from "../../../../../models/closures";
import {Level} from "../../../../../models/level";

@Component({
  selector: 'app-levels-list',
  templateUrl: './levels-list.component.html',
  styleUrls: ['./levels-list.component.css']
})
export class LevelsListComponent extends AbstractClosureDetailsComponent{

  displayedColumns = ['tankNumber', 'product', 'quantity', 'height', 'temperature'];

  data: Level[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    protected closureService: ClosuresService,
    protected logger: NGXLogger
  ) {
    super(modalData,closureService, logger)
  }

  ngOnInit() {

    this.filter = {
      type: ClosureDetailsType.Levels
    };

    this.fetchData((res: ClosureDetail) => {
      this.data = res.levels;
    })
  }

}
