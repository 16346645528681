<mat-toolbar class="topbar">
  <app-branding></app-branding>

  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  <button
    mat-icon-button
    (click)="toggleCollapsed.emit()"
    *ngIf="showToggle"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-40 d-flex"></i-tabler>
  </button>

  <!-- Mobile Menu -->
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    *ngIf="!showToggle"
    class="d-flex justify-content-center"
  >
    <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
  </button>
  <!-- --------------------------------------------------------------- -->

  <span class="flex-1-auto text-center">
    <img src="./assets/images/logo.png" class="d-lg-none" width="200">
  </span>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Notifications"
    style="padding: 0px;"
  >
    <img
      src="/assets/images/users/profile.png"
      class="rounded-circle object-cover"
      width="55"
      style="width: 40px; height: 40px;"
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar
      class="position-relative"
      style="height: 290px"
    >
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0">Profilo utente</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <img
            src="/assets/images/users/profile.png"
            class="rounded-circle"
            width="95"
          />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0">{{user?.username}}</h6>
          </div>
        </div>
      </div>

      <div class="p-y-12 p-x-32">
        <a
          (click)="logout()"
          mat-stroked-button
          color="primary"
          class="w-100"
          >Esci</a
        >
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
