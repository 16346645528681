import { Component } from '@angular/core';
import {Formatter} from "../../../utils/formatter";
import {AbstractTotalComponent} from "../abstract-total.component";

@Component({
  selector: 'app-total-amount',
  templateUrl: './total-amount.component.html',
  styleUrls: ['./total-amount.component.css']
})
export class TotalAmountComponent extends AbstractTotalComponent{

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  formatTotal(): string {
    return Formatter.formatAmount(this.total, 0);
  }

}
