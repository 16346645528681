import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {User, Login, Refresh} from "../models/user";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(protected http: HttpClient) {
    this.userSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('user') ?? 'null'));
    this.user = this.userSubject.asObservable();
  }

  /**
   * @description ottengo l'attuare stato dell'utente, se è nullo provo a recuperarlo dallo storage
   */
  get userValue(): User | undefined {

    if (this.userSubject.value === null) {
      this.userSubject.next(JSON.parse(localStorage.getItem('current_user') ?? 'null'));
      this.user = this.userSubject.asObservable();
    }

    if (!this.userSubject.value) {
      return undefined;
    }

    return <User> this.userSubject.value;
  }

  /**
   * @description Chiamata di login
   * @param username
   * @param password
   */
  login(username: string, password: string): Observable<Login> {
    return this.http.post(environment.api + '/auth/login', {username, password}).pipe(
      map(result => <Login>result)
    );
  }

  /**
   * @description Salvataggio dei dati come stato del servizio
   * @param user
   */
  saveUser(user: Login | Refresh) {
    const currentUser: User = new User(user.userToken, user.userRefreshToken);

    localStorage.setItem('current_user', JSON.stringify(currentUser));
    this.userSubject.next(currentUser);
    this.user = this.userSubject.asObservable();
  }

  /**
   * @description Pulizia sessione
   */
  deleteUser() {
    localStorage.removeItem('current_user');
    this.userSubject.next(null);
  }

  /**
   * @description Verifica dello stato dell'utente e del token
   */
  isLoggedIn(): boolean {
    let user: User | undefined = this.userValue;

    if (user === null || user === undefined) {
      return false;
    }

    return !(user.token === undefined || user.token === null || !user.token.length);
  }

  /**
   * @description Verifica che il token sia valido
   */
  isTokenValid(): boolean {

    if (!this.userValue) {
      return false;
    }

    return !(this.userValue.exp < (Date.now() / 1000));
  }

  /**
   * @description Chiamata di refresh del token
   * @param token
   */
  refreshToken(token: string): Observable<Refresh> {
    return this.http.post(environment.api + '/auth/refresh', {refreshToken: token}).pipe(
      map(result => <Refresh>result)
    )
  }

}
