import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ClosuresService} from "../../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {ClosureDetailsType} from "../../../../../enum/closure-details-type";
import {ClosureDetail} from "../../../../../models/closures";
import {Cash, CashDetails} from "../../../../../models/cash";
import {AbstractClosureDetailsComponent} from "../abstract-closure-details-component";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-cash-list',
  templateUrl: './cash-list.component.html',
  styleUrls: ['./cash-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CashListComponent extends AbstractClosureDetailsComponent{

  displayedColumns = ['payment', 'service', 'amount', 'expand'];
  expandedElement: CashDetails | null = null;

  data: Cash[] = [];

  totalAmountValue: number;
  totalAmountCorrectValue: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    protected closureService: ClosuresService,
    protected logger: NGXLogger
  ) {
    super(modalData,closureService, logger);
    this.totalAmountValue = 0;
    this.totalAmountCorrectValue = 0;
  }

  ngOnInit() {

    this.filter = {
      type: ClosureDetailsType.Cash
    };

    this.fetchData((res: ClosureDetail) => {
      this.data = res.cash;

      this.data.forEach((cash) => {
        this.totalAmountValue += cash.totalAmount.amount;
        this.totalAmountCorrectValue += cash.totalAmount.amountCorrect;
      });

      this.data.sort((a,b) => a.service.id - b.service.id);

    })
  }

}
