import { Component, Inject, Input, ViewChild } from '@angular/core';
import { NgIf, NgFor, CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { SalesBarPeriod } from '../dashboard-graphs/sales-pie/sales-pie.component';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Formatter } from '../../utils/formatter';
import { TablerIconsModule } from 'angular-tabler-icons';
import { Plant } from 'src/app/models/plant';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TablerIconsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxMatSelectSearchModule
  ],
})
export class DialogComponent {
  @Input() showPlants: boolean = false;
  showDatePicker: boolean = false;
  date = new FormControl(new Date());

  results: dialogParams = {
    period: 0,
    periodList: [],
    plantsList: [],
    plant: [],
    dates: {},
  };

  reset: dialogParams = {
    period: 0,
    periodList: [],
    plantsList: [],
    plant: [],
    dates: {},
  };

  plantForm: number[] | undefined;

  /** PER SELECT SEARCH PLANTS */
  /** controllo l'impianto selezionato */
  public plantCtrl: FormControl = new FormControl();

  /** controllo la parola chiave inserita nel filtro. */
  public plantFilterCtrl: FormControl = new FormControl();

  /** lista degli impianti filtrata per parola chiave di ricerca  */
  public filteredPlants: ReplaySubject<Plant[]> = new ReplaySubject<Plant[]>(1);

  protected _onDestroy = new Subject<void>();

  plantsObjects: Plant[] = [];

  @ViewChild("singleSelect") singleSelect: MatSelectModule;
  /** FINE CAMPO SELECT SERARCH PLANTS */

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dialogParams
  ) {
    this.results = {
      period: data.period,
      dates: {
        from: data.dates.from,
        to: data.dates.to,
      },
      periodList: data.periodList,
      plantsList: data.plantsList,
      plant: data.plant,
    };

    this.plantForm = this.results.plant;
    
    // Carico la lista impianti iniziale
    this.plantsObjects = [];
    
    if (this.results.plantsList){
      this.results.plantsList.forEach((plant) => {
        this.plantsObjects.push(plant);
      });
    }
    console.log(this.plantsObjects)
    this.filteredPlants.next(this.plantsObjects.slice());

    // Rimango in ascolto per il cambiamento del valore sul campo di input
    this.plantFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPlants();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  applyFilters(key: string, value: any) {
    if (key === "period") {
      this.results.period = value;
      this.showDatePicker =
        this.results.period === SalesBarPeriod.CUSTOM ? true : false;
    }

    if (key === "from") {
      this.results.dates.from = Formatter.formatFilterDate(value);
    }

    if (key === "to") {
      this.results.dates.to = Formatter.formatFilterDate(value);
    }

    if (key === "plant") {
      this.results.plant = value;
    }
  }

  /**  imposto i filtri per la select impianto con ricerca */
  protected filterPlants() {
    if (!this.plantsObjects) {
      return;
    }
    // ottengo la parola ricercata
    let search = this.plantFilterCtrl.value;
    if (!search) {
      this.filteredPlants.next(this.plantsObjects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro gli impianti
    this.filteredPlants.next(
      this.plantsObjects.filter(
        (plant) => plant.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  /** metodo per azzerare i valori della select impianti */
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

export type dialogParams = { 
  periodList: any[],
  plantsList?: Plant[],
  plant?: number[],
  period: number, 
  dates: { 
    from?: Date | null, 
    to?: Date 
  }
}
