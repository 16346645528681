import {Component} from '@angular/core';
import {AuthenticationService} from "./services/authentication.service";
import {Role} from "./enum/role";

@Component({
  template: '',
})
export class AbstractPageComponent {

  role: Role;

  constructor(
    protected authService: AuthenticationService
  ) {
    this.role = authService.userValue ? authService.userValue.level : Role.UNKNOWN;
  }

  get roles(): typeof Role {
    return Role;
  }

}
