<mat-card style="min-height: 90%">

  <mat-card-header *ngIf="title.length">
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="!loaded.value">
    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
  </mat-card-content>

  <mat-card-content *ngIf="loaded.value">
    <div class="table-responsive">
      <table mat-table [dataSource]="data">

        <!-- Tank Column -->
        <ng-container matColumnDef="tankNumber">
          <th mat-header-cell *matHeaderCellDef> Numero serbatoio</th>
          <td mat-cell *matCellDef="let element">
            {{element.tankNumber}}
          </td>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef> Prodotto</th>
          <td mat-cell *matCellDef="let element">
            {{element.product.name}}
          </td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Quantit&agrave;</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{
            !isDiverted(element.quantity.total) ?
              formatQuantity(element.quantity.total, element.product.id) :
              '-'
            }}
          </td>
        </ng-container>

        <!-- Height Column -->
        <ng-container matColumnDef="height">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Altezza</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{element.height ? (element.height + ' cm') : '-'}}
          </td>
        </ng-container>

        <!-- Temperature Column -->
        <ng-container matColumnDef="temperature">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Temperatura</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{element.temperature ? (element.temperature + ' C°') : '-'}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="isModalView" class="mt-4 text-center">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Chiudi</button>
</div>

