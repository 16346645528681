<mat-card class="chart-card" style="height: 470px !important;">
  <mat-progress-bar *ngIf="!loaded.value" mode="indeterminate"></mat-progress-bar>
  <mat-card-header>
    <div class="card-header-container">
      <div>
        <mat-card-title *ngIf="title.length" class="mt-2">{{title}}</mat-card-title>
        <mat-card-subtitle *ngIf="subtitle.length">{{subtitle}}</mat-card-subtitle>
      </div>      
      <mat-form-field>
        <mat-label>Fino all'anno</mat-label>
        <mat-select class="pt-6" [(ngModel)]="selectedYear">
          <mat-option *ngFor="let year of yearList" (click)="changeYear(year)" [value]="year">{{year}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </mat-card-header>

  <mat-card-content>

    <div id="sales-chart-bar" class="d-flex flex-column p-b-5">
      <apx-chart
        [series]="SalesBarChartOptions.series"
        [chart]="SalesBarChartOptions.chart"
        [dataLabels]="SalesBarChartOptions.dataLabels"
        [plotOptions]="SalesBarChartOptions.plotOptions"
        [yaxis]="SalesBarChartOptions.yaxis"
        [legend]="SalesBarChartOptions.legend"
        [stroke]="SalesBarChartOptions.stroke"
        [tooltip]="SalesBarChartOptions.tooltip"
        [xaxis]="SalesBarChartOptions.xaxis"
        [grid]="SalesBarChartOptions.grid"
        [noData]="SalesBarChartOptions.noData"
      >
      </apx-chart>
    </div>
  </mat-card-content>
</mat-card>
