import {Product} from "../../models/product";
import {Products} from "../../enum/products";

export class Formatter {

  static MONTHS = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ];

  static formatDate(date: Date|string, monthLiteral: boolean = false, separator = '-', fullYear = true, time = false) {

    if (typeof date === 'string') {
      date = new Date(date);
    }

    const formatted = Formatter.defaultFormatDate(date);

    if (!fullYear) {
      formatted.year = formatted.year.substring(2);
    }

    if (monthLiteral) {
      formatted.month = Formatter.MONTHS[date.getMonth()];
      return `${formatted.day} ${formatted.month} ${formatted.year}`;
    }

    const response = `${formatted.day}${separator}${formatted.month}${separator}${formatted.year}`;

    if (time) {
      let hours: number | string = date.getHours();
      let minutes: number | string = date.getMinutes();

      if (hours < 10) {
        hours = '0' + hours;
      }

      if (minutes < 10) {
        minutes = '0' + minutes;
      }

      return response + ` ${hours}:${minutes}`;
    }

    return response;
  }

  static defaultFormatDate(date: Date): {day: string, month: string, year: string} {
    let year = date.getFullYear();
    let month: string | number = date.getMonth() + 1;
    let day: string | number = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }
    else {
      month = month.toString();
    }

    if (day < 10) {
      day = '0' + day;
    }
    else {
      day = day.toString()
    }

    return {
      day: day,
      month: month,
      year: year.toString()
    }
  }

  static formatAmount(amount: number, digits = 2) {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: digits,
      minimumFractionDigits: digits
    }).format(amount);
  }

  static formatPrice(amount: number) {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    }).format(amount);
  }

  static formatQuantity(quantity: number, product: Product, digits = 3) {

    let um = '';

    switch (product.id) {
      case Products.NonOil:
        um = ' Pz.';
        break;
      default:
        um = ' Lt.';
        break;
    }

    return new Intl.NumberFormat('it-IT', {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits
    }).format(quantity) + um;
  }

  static formatFilterDate(date: any, end = false) {

    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    let tmp = Formatter.defaultFormatDate(date);

    date = `${tmp.year}-${tmp.month}-${tmp.day} `;

    if (end) {
      date += '23:59:59';
    }
    else {
      date += '00:00:00'
    }

    return date;

  }
}
