import {Injectable} from '@angular/core';
import {Role} from "../../enum/role";

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  roles: Role[]
}

const MENUITEMS: Menu[] = [
  {
    state: 'dashboard',
    name: 'menu.dashboard',
    type: 'link',
    icon: 'dashboard',
    roles: [Role.NET, Role.PLANT]
  },
  {
    state: '/transactions',
    name: '/transactions',
    type: 'link',
    icon: 'receipt_long',
    roles: [Role.NET, Role.PLANT]
  },
  {
    state: 'closures',
    name: 'menu.closures',
    type: 'link',
    icon: 'today',
    roles: [Role.NET, Role.PLANT]
  },
  {
    state: 'business-intelligence',
    name: 'menu.business-intelligence',
    type: 'link',
    icon: 'analytics',
    roles: [Role.NET],
    badge: [{
      type: 'warning',
      value: 'beta'
    }]
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
