import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  Observable,
  ObservableInput,
  switchMap,
  take,
  throwError
} from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";
import {Refresh, User} from "../models/user";
import {filter} from "rxjs/operators";
import {NGXLogger} from "ngx-logger";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private user?: User | null = null;

  constructor(
    private authService: AuthenticationService,
    private logger: NGXLogger
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    this.user = this.authService.userValue;

    // Login post request
    if (this.user === null) {
      return throwError("User not authenticated");
    }
  
    const token = this.user ? this.user.token : undefined;
  
    let authReq = ApiInterceptor.addTokenHeader(req, token);
  
    return next.handle(authReq).pipe(      
      catchError((err: any) => {
        if (!authReq.url.includes('auth/signin') && err.status === 401) {
          this.logger.error('Request status code: ', err.status);
          this.logger.error('Token refreshing...');
          return this.handle401Error(authReq, next);
        }
        return throwError(err);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): ObservableInput<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.user?.refreshToken;

      if (token) {
        return this.authService.refreshToken(token).pipe(
        switchMap((res: Refresh) => {
          this.isRefreshing = false;
          this.authService.saveUser(res);
          this.refreshTokenSubject.next(res.userToken);

          return next.handle(ApiInterceptor.addTokenHeader(request, res.userToken));
        }),
          catchError(err => {
            this.isRefreshing = false;

            this.authService.deleteUser();
            return throwError(err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(ApiInterceptor.addTokenHeader(request, token)))
    );
  }


  private static addTokenHeader(request: HttpRequest<any>, token?: string) {
    return request.clone({ headers: request.headers.set('Authorization',  `Bearer ${token ?? ''}`) });
  }

}
