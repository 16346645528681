import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApexOptions, ChartComponent} from "ng-apexcharts";
import {getMonthsNames} from "../../../../enum/months";
import {SalesService} from "../../../../services/sales.service";
import {BehaviorSubject, catchError, fromEvent, map, merge, of, startWith, switchMap} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {SalesTotalsByMonthResponse} from "../../../../models/sales-totals";
import {Role} from "../../../../enum/role";
import {AuthenticationService} from "../../../../services/authentication.service";
import {Plant} from "../../../../models/plant";
import {Product} from "../../../../models/product";

@Component({
  selector: 'app-dashboard-sales-bar',
  templateUrl: './sales-bar.component.html',
  styleUrls: ['./sales-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardSalesBarComponent implements OnInit {
  @ViewChild('sales-chart-bar') chart: ChartComponent = Object.create(null);
  public SalesBarChartOptions: Partial<ApexOptions>;

  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  years = 1;

  @Input()
  filters: any = {
    from: '',
    to: '',
  };

  private readonly currentYear: number =  new Date().getFullYear();
  fromYear: number = this.currentYear;
  selectedYear: number = this.currentYear;

  yearList: number[] = [];
  yearLabel: number[] = [];

  plantsList: Plant[] = [];
  productsList: Product[] = [];

  filterChange = new BehaviorSubject<boolean>(false);
  loaded = new BehaviorSubject<boolean>(false);

  constructor(
    private salesApi: SalesService,
    private authService: AuthenticationService,
    private logger: NGXLogger
  ) {
    this.logger.debug('Year: ', this.years);

    this.SalesBarChartOptions = {
      series: [],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 350,
        stacked: false
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "14px"
          },
        },
        categories: getMonthsNames(),
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px"
          },
          formatter(val: number, opts?: any): string | string[] {

            return new Intl.NumberFormat('it-IT', {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0
            }).format(val) + ' Lt.';
          }
        }
      },
      legend: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        theme: 'dark',
      },
      noData: {
        text: 'Nessun dato disponibile',
        style: {color: '#26c6da'}
      },
    };
  }

  ngOnInit(): void {
    this.setYearsList();
    this.getData();
  }

  setYearsList() {
    this.yearList = [];    

    for (let i = this.selectedYear - this.years + 1; i <= this.currentYear; i++) {
      this.yearList.push(i);
    }    
  }

  setYearLabel() {
    this.yearLabel = [];
    for (let i = this.selectedYear - this.years + 1; i <= this.selectedYear; i++){      
      this.yearLabel.push(i);
    }
  }

  changeYear(year: number){
    this.selectedYear = year;    
    this.filterChange.next(true);
  }


  getData() {
    merge(this.filterChange).pipe(
      switchMap(() => {
        this.loaded.next(false);

        this.setYearLabel();
        
        // Date filters formatted
        let tmp: Date = new Date(`${this.selectedYear - this.years + 1}-01-01`);
        this.filters.from = tmp.toISOString();

        tmp = new Date(`${this.selectedYear}-12-31`);
        tmp.setHours(23, 59, 59);
        this.filters.to = tmp.toISOString();

        this.logger.debug('Sales bar filters: ', this.filters);

        return this.salesApi.getTotalsByMonth(this.filters)
      }),
      map((data: SalesTotalsByMonthResponse) => {

        this.logger.debug('Sales totals response data: ', data);
        let formattedData: SalesBarChartData[] = [];

        data.meta.plants.forEach((p) => {
          this.plantsList.push(p);
        });

        data.meta.products.forEach((p) => {
          this.productsList.push(p);
        });

        for (let year of this.yearLabel) {
          formattedData.push({
            name: year.toString(),
            data: [0,0,0,0,0,0,0,0,0,0,0,0]
          });
        }

        for (let row of data.data) {
          const index = formattedData.findIndex(el => el.name === row.date.year.toString());
          const series = formattedData[index];

          if (series !== undefined) {
            series.data[row.date.month - 1] = row.quantity;
          }

          formattedData[index] = series;
        }

        return formattedData;

      }),
      catchError(() => {
        this.loaded.next(false);
        return of([]);
      }),
    ).subscribe((data) => {
      this.logger.debug('Sales totals formatted data: ', data);
      this.loaded.next(true);
      this.SalesBarChartOptions.series = data;
    });  
  }  
}

type SalesBarChartData = { name: string, data: number[] }
