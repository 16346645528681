<mat-card style="min-height: 90%">

  <mat-card-header *ngIf="title.length">
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="!loaded.value">
    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
  </mat-card-content>

  <mat-card-content *ngIf="loaded.value">
    <div class="table-responsive">
      <table mat-table [dataSource]="data" multiTemplateDataRows>

        <!-- Service Column -->
        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef> Tipologia </th>
          <td mat-cell *matCellDef="let element">
            {{element.service.name}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Payment Column -->
        <ng-container matColumnDef="payment">
          <th mat-header-cell *matHeaderCellDef> Metodo di pagamento</th>
          <td mat-cell *matCellDef="let element">
            {{element.payment.name}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef><strong>TOTALE</strong></td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Importo Totale</th>
          <td mat-cell *matCellDef="let element" class="text-end {{isDiverted(element.totalAmount.amount) ? 'text-danger' : ''}}">
            {{formatAmount(element.totalAmount.amount)}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}} text-end" mat-footer-cell *matFooterCellDef><strong>{{totalAmount(totalAmountCorrectValue)}}</strong></td>

        </ng-container>

        <!-- Expand Column -->
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <i *ngIf="element.details !== null" class="fa {{(expandedElement === element) ? 'fa-chevron-up' : 'fa-chevron-down'}} fa-lg text-secondary"
               matTooltip="{{(expandedElement === element) ? 'Riduci' : 'Espandi'}}">&nbsp;</i>
          </td>
          <td class="{{showTotals ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <cash-details-list
                class="mt-5 mb-5 w-100" style="border: 1px solid #ddd; border-radius: 5px; padding: 20px"
                [details]="element.details"
              ></cash-details-list>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>
        <tr class="{{showTotals ? '' : 'd-none'}}" mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="isModalView" class="mt-4 text-center">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Chiudi</button>
</div>

