import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ClosuresService} from "../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {BehaviorSubject, catchError, map, merge, of, startWith, switchMap} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ClosureInfo, ClosurePlantListResponse, ClosurePlantTransmissions} from "../../../../models/closures";
import {Formatter} from "../../../utils/formatter";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FilterAwareComponent} from "../../../interfaces/filter-aware-component";
import {MatDialog} from "@angular/material/dialog";
import {SalesListComponent} from "../closure-details/sales-list/sales-list.component";
import {ClosureDetailsType} from "../../../../enum/closure-details-type";
import {CashListComponent} from "../closure-details/cash-list/cash-list.component";
import {AuthenticationService} from "../../../../services/authentication.service";
import {Role} from "../../../../enum/role";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LevelsListComponent} from "../closure-details/levels-list/levels-list.component";
import {TotalizersListComponent} from "../closure-details/totalizers-list/totalizers-list.component";
import {HttpStatusCode} from "@angular/common/http";
import {ExportService} from "../../../../services/export.service";

@Component({
  selector: 'plant-closure-list',
  templateUrl: './plant-closure-list.component.html',
  styleUrls: ['./plant-closure-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PlantClosureListComponent implements AfterViewInit, FilterAwareComponent {

  @Input()
  plantId: number = 0;

  @Input()
  title = '';

  @Input()
  showFilter = false;

  @Input()
  showPaging = false;

  @Input()
  displayedColumns = [];

  @Input()
  filter: any = {
    from: new Date(new Date().setDate(1)),
    to: new Date(),
    sort: 'date',
    order: 'desc',
    page: 1,
    pageSize: 50
  };

  @Input()
  showExport = true;

  @Input()
  exportLimit = 31;

  isExportLoading = false;


  detailsType = ClosureDetailsType;

  formatDate = Formatter.formatDate;

  data: any[] = [];
  resultsLength = 0;

  filterChanged = new BehaviorSubject<boolean>(false);
  loaded = new BehaviorSubject<boolean>(false);
  showProgress = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatSort) sort: MatSort = Object.create(null);
  @ViewChild('input') input: ElementRef | undefined;
  constructor(
    private closureService: ClosuresService,
    private logger: NGXLogger,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    public snackbar: MatSnackBar
  ) {

    if (authService.userValue && authService.userValue.level === Role.PLANT) {

      if (Array.isArray(authService.userValue.plants)) {
        this.plantId = authService.userValue.plants[0];
      }
      else {
        this.plantId = authService.userValue.plants;
      }

    }

  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filterChanged)
      .pipe(
        switchMap(() => {
          this.loaded.next(false);

          if (this.showFilter) {
            this.filter.sort = this.sort.active;
            this.filter.order = this.sort.direction;
            this.filter.page = this.paginator.pageIndex + 1;
            this.filter.pageSize = this.paginator.pageSize;
          }

          this.logger.debug('Plant closure list filters: ',this.filter);

          return this.closureService.plantList(this.plantId, this.filter);
        }),
        map((data: ClosurePlantListResponse) => {

          this.logger.debug('Plant closure list responda data: ',data);
          this.loaded.next(true);

          // Flip flag to show that loading has finished.
          this.resultsLength = data.totals.rows;

          const closure: ClosureInfo[] = [];

          data.data.forEach((row) => {
            closure.push(row.closure);
          })

          return closure;
        }),
        catchError(() => {
          this.loaded.next(false);
          return of([]);
        }),
      )
      .subscribe((data: ClosureInfo[]) => {
        this.logger.debug('Plant closure list formatted data: ',data);
        this.data = data;
      });

  }

  applyFilter(key: string, value: any) {

    // this.logger.debug(key);
    // this.logger.debug(value);

    if (value) {

      if (key === 'from' || key === 'to') {
        value = new Date(value);

        if (key === 'from') {
          value.setHours(0,0,0);
        }
        else {
          value.setHours(23, 59, 59);
        }

        value = value.toISOString();
      }

      this.filter[key] = value;
    }
    else {
      delete this.filter[key];
    }
  }

  filterData() {
    this.paginator.pageIndex = 0;
    this.filterChanged.next(true);
  }

  getMissingTransmissionRow(transmissions: ClosurePlantTransmissions): string {

    let count = transmissions.missing.length

    let msg = count > 1 ? 'Servizi ' : 'Servizio ';

    if (count === 2) {
      msg += '"' + transmissions.missing[0] + '" e "' + transmissions.missing[1] + '"';
    }
    else {
      for (let i = 0; i < count; i++) {
        msg += '"';
        msg += transmissions.missing[i];
        msg += '"';

        if (i < (count - 1)) {
          msg += ','
        }
      }
    }

    msg += count > 1 ? ' mancanti' : 'mancante';

    msg += ' su un totale di ' + transmissions.clients.length + ' servizi configurati'

    return msg;
  }

  openDetails(closure: ClosureInfo, type: ClosureDetailsType) {

    let ref: any;
    let title = '';
    let showTotals = false;

    const date =  Formatter.formatDate(closure.dates.accounting, true);

    switch (type) {
      case ClosureDetailsType.Sales:
         ref = SalesListComponent;
         title = `Vendite chiusura ${date}`;
         showTotals = true;
         break;
      case ClosureDetailsType.Cash:
        ref = CashListComponent;
        title = `Incassi chiusura ${date}`;
        showTotals = true;
        break;
      case ClosureDetailsType.Levels:
        ref = LevelsListComponent;
        title = `Livelli chiusura ${date}`;
        break;
      case ClosureDetailsType.Totalizers:
        ref = TotalizersListComponent;
        title = `Totalizzatori chiusura ${date}`;
        break;
    }

    this.dialog.open(ref, {
      width: '80%',
      height: '80%',
      data: {
        closureId: closure.id,
        title: title,
        showTotals: showTotals
      }
    })

  }

  addTransmission(date: string) {

    const formatted = Formatter.defaultFormatDate(new Date(date));

    let plantId = this.plantId;

    this.logger.debug('PlantId: ', plantId);

    this.showProgress.next(true);

    this.closureService.add({
      type: Type.OUT,
      plant_id: plantId,
      date: `${formatted.year}-${formatted.month}-${formatted.day}`,
      close: undefined
    }).subscribe({
      next: (res: any) => {

        let showWarning = false;
        let total_responses = 0;
        let success_responses = 0;
        let message;

        if (!res.response) {
          showWarning = true;
          message = 'Nessuna trasmissione effettuata';
        }
        else {
          res.response.forEach((r: any) => {
            if (r.statusCode && r.statusCode === HttpStatusCode.Ok) {
              success_responses++;
            }
            total_responses++;
          });

          if (total_responses > success_responses) {
            showWarning = true;
          }

          message = `${success_responses}/${total_responses} trasmissioni effettuate `
        }

        this.showProgress.next(false);
        this.snackbar.open(message, 'Ok', {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: [(showWarning) ? 'warning-snackbar' : 'success-snackbar']
        });
      },
      error: (err) => {
        this.logger.error(err);
        this.showProgress.next(false);
        this.snackbar.open('Si è verificato un errore', 'Ok', {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar']
        });
      }
    })

  }

  exportAll() {
    const filter = {...this.filter};
    filter.page = 1;
    filter.pageSize = this.exportLimit;
    this.isExportLoading = true;

    this.closureService.plantList(this.plantId, filter).subscribe({
      next: (data) => {
        this.logger.debug('Export closures data: ',data);
        const exportData: ExportStruct[] = [];
        data.data.forEach((element) => {

          const dates = element.closure.dates;
          const totals = element.closure.totals;

          if (dates.accounting instanceof Date) {
            dates.accounting = dates.accounting.toISOString();
          }

          if (dates.period.start instanceof Date) {
            dates.period.start = dates.period.start.toISOString();
          }

          if (dates.period.end instanceof Date) {
            dates.period.end = dates.period.end.toISOString();
          }

          let missing = '';

          element.closure.transmissions.missing.forEach((el, index) => {
            missing += el;

            if (index < element.closure.transmissions.missing.length - 1) {
              missing += ', ';
            }
          })

          exportData.push({
            "Data Contabile": Formatter.formatDate(dates.accounting),
            Da: Formatter.formatDate(dates.period.start),
            A: Formatter.formatDate(dates.period.end),
            Vendite: totals.sales,
            Incassi: totals.cash,
            Livelli: totals.levels,
            Transazioni: totals.transactions,
            Trasmissioni: element.closure.transmissions.total,
            "Trasmissioni mancanti": missing
          });
        });

        this.logger.debug('Closure list data to export: ',exportData);

        ExportService.xlsx('chiusure', filter, exportData, true);
      },
      error: (e) => {
        this.logger.error(e);
        throw e;
      },
      complete: () => {
        this.isExportLoading = false;
      }
    });
  }

}

enum Type {
  IN='in',
  OUT='out'
}

type ExportStruct = {
  'Data Contabile': string,
  'Da': string,
  'A': string,
  Vendite: number,
  Incassi: number,
  Livelli: number,
  Transazioni: number,
  Trasmissioni: number,
  "Trasmissioni mancanti": string
}

