import { Injectable } from '@angular/core';
import {Transaction, TransactionListResult, TransactionMeta} from "../models/transaction";
import {map} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Formatter} from "../shared/utils/formatter";

@Injectable({
  providedIn: 'root'
})
export class TransactionsService{

  protected endpoint: string;

  constructor(protected http: HttpClient) {
    this.endpoint = `${environment.api}/transactions`;
  }

  plantList(params: {
    sort: 'dateTime',
    order: 'desc',
    page: 1,
    pageSize: 10,
    from?: string,
    to?: string,
    plant?: number[] | number
  }) {

    let p = JSON.parse(JSON.stringify(params));

    if (params.from) {
      p.from = Formatter.formatFilterDate(params.from);
    }

    if (params.to) {
      p.to = Formatter.formatFilterDate(params.to, true);
    }

    if (params.plant) {
      p.plant = JSON.stringify(params.plant)
    }

    return this.http.get(`${this.endpoint}`, {
      params: p
    }).pipe(
      map((res) => <TransactionListResult>res)
    );
  }

}
