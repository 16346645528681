<mat-card>
  <mat-progress-bar *ngIf="!loaded.value" mode="indeterminate"></mat-progress-bar>
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle *ngIf="subtitle.length">{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form id="closure-add-form" [formGroup]="form" (ngSubmit)="onSubmit()">

      <mat-form-field class="m-2 w-50">
        <mat-select placeholder="Data contabile" formControlName="date">
          <mat-option *ngFor="let date of dates" [value]="date">
            {{formatDate(date, true)}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-slide-toggle formControlName="close" class="m-2 ms-5" (change)="alertClose($event)">Forza chiusura a zero</mat-slide-toggle>

      <div class="m-2 mt-5">
        <div class="w-100">
          <button mat-raised-button color="primary" type="submit">Acquisisci</button>
          &nbsp;
          <button *ngIf="isModalView" mat-raised-button color="warn" [mat-dialog-close]="true" class="m2-3">Annulla</button>
        </div>
      </div>

    </form>
  </mat-card-content>
</mat-card>


