import { Injectable } from '@angular/core';
import {Formatter} from "../shared/utils/formatter";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  /**
   * @description Esportazione file in xlsx
   * @param filename
   * @param filter
   * @param data
   * @param addDatesFromFilters
   */
  static xlsx(filename: string, filter: any, data: any, addDatesFromFilters: boolean = false) {

    // Aggiungi date al nome del file
    if (addDatesFromFilters) {
      if (filter.from) {
        filename += ( '_' + Formatter.formatDate(filter.from));
      }

      if (filter.to) {
        filename += ( '_' + Formatter.formatDate(filter.to));
      }
    }

    filename += '.xlsx';

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(data));
    XLSX.writeFileXLSX(wb, filename);

  }
}
