import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {CustomPaginator} from "./utils/custom-paginator";
import {TransactionListComponent} from "./components/transaction-list/transaction-list.component";
import {MatSortModule} from "@angular/material/sort";
import {MatCardModule} from "@angular/material/card";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonModule} from "@angular/material/button";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {CommonModule} from "@angular/common";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import { PlantClosureListComponent } from './components/closures/plant-closure-list/plant-closure-list.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatListModule} from "@angular/material/list";
import {MatDialogModule} from "@angular/material/dialog";
import { CashListComponent } from './components/closures/closure-details/cash-list/cash-list.component';
import {AbstractClosureDetailsComponent} from "./components/closures/closure-details/abstract-closure-details-component";
import {MatIconModule} from "@angular/material/icon";
import { ClosureAddComponent } from './components/closures/closure-add/closure-add.component';
import {FlexModule} from "@angular/flex-layout";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { ConfirmDialogComponent } from './utils/confirm-dialog/confirm-dialog.component';
import {A11yModule} from "@angular/cdk/a11y";

import {NgApexchartsModule} from "ng-apexcharts";

import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { MatTabsModule } from '@angular/material/tabs';
import { TablerIconsModule } from 'angular-tabler-icons';

import { LevelsListComponent } from './components/closures/closure-details/levels-list/levels-list.component';
import { TotalizersListComponent } from './components/closures/closure-details/totalizers-list/totalizers-list.component';

import { TotalAmountComponent } from './components/totals/total-amount/total-amount.component';
import { TotalQuantityComponent } from './components/totals/total-quantity/total-quantity.component';
import { AbstractTotalComponent } from './components/totals/abstract-total.component';
import { CashDetailsListComponent } from './components/closures/closure-details/cash-details-list/cash-details-list.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// Dashboard graphs
import { DashboardSalesBarComponent } from './components/dashboard-graphs/sales-bar/sales-bar.component';
import { DashboardSalesAreaComponent } from './components/dashboard-graphs/sales-area/sales-area.component';
import { DashboardSalesPieComponent } from './components/dashboard-graphs/sales-pie/sales-pie.component';

// Analisi graphs
import { SalesListComponent } from './components/closures/closure-details/sales-list/sales-list.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TransactionListComponent,
    PlantClosureListComponent,
    AbstractClosureDetailsComponent,
    SalesListComponent,
    CashListComponent,
    ClosureAddComponent,
    ConfirmDialogComponent,
    DashboardSalesBarComponent,
    DashboardSalesPieComponent,
    LevelsListComponent,
    TotalizersListComponent,
    TotalAmountComponent,
    TotalQuantityComponent,
    AbstractTotalComponent,
    CashDetailsListComponent
  ],
    imports: [
        MatSortModule,
        MatCardModule,
        NgxDatatableModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        MatDatepickerModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        CommonModule,
        NgxSkeletonLoaderModule,
        MatTooltipModule,
        MatListModule,
        MatDialogModule,
        MatIconModule,
        ReactiveFormsModule,
        FlexModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        A11yModule,
        NgApexchartsModule,
        MatButtonToggleModule,
        NgxMatSelectSearchModule,
        TablerIconsModule,
        MatTabsModule,
        MatDialogModule
    ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TransactionListComponent,
    PlantClosureListComponent,
    DashboardSalesBarComponent,
    DashboardSalesPieComponent,
  ],
  providers: [ MenuItems, {provide: MatPaginatorIntl, useClass: CustomPaginator} ]
})
export class SharedModule { }
