import {Component, Input, OnInit} from '@angular/core';
import {CashDetails} from "../../../../../models/cash";
import {Formatter} from "../../../../utils/formatter";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'cash-details-list',
  templateUrl: './cash-details-list.component.html',
  styleUrls: ['./cash-details-list.component.css']
})
export class CashDetailsListComponent implements OnInit {

  private _totalAmount: number = 0.00;

  @Input()
  details: CashDetails[] | null = null;

  @Input()
  showTotals = true;

  noData = false;

  displayedColumns = ['payment', 'amount'];

  formatAmount = Formatter.formatAmount;

  getTotalAmount() {
    return this.formatAmount(this._totalAmount);
  }

  constructor(
    protected logger: NGXLogger
  ) {}

  ngOnInit(): void {
    if (this.details === null) {
      this.noData = true;
      return;
    }

    this.details.forEach((detail) => {
      this._totalAmount += detail.amount;
    });

    this.logger.info(this._totalAmount);
  }

}
