<div class="flex">
  <div
    class="user-profile bg-primary profile-bar"
    style="background: url(assets/images/backgrounds/user-info.jpg) no-repeat"
  >
    <!-- User profile image -->
    <div class="profile-img">
      <img
        src="assets/images/users/profile.png"
        alt="user"
        width="50"
        class="rounded-circle"
      />
    </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text">
      <a
        [matMenuTriggerFor]="sdprofile"
        class="d-flex align-items-center text-white w-100 f-s-16"
      >
        {{user?.username}}
        <i-tabler name="chevron-down" class="icon-18 m-l-auto"></i-tabler>
      </a>
    </div>
    <mat-menu
      #sdprofile="matMenu"
      xPosition="after"
      class="cardWithShadow profile-dd"
    >
      <a mat-menu-item (click)="logout()"
        ><mat-icon class="d-flex align-items-center"
          ><i-tabler name="logout" class="icon-18 d-flex"></i-tabler
        ></mat-icon>
        Esci</a
      >
    </mat-menu>
  </div>
</div>
