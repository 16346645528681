export enum Products {
  Sconosciuto,
  Metano,
  Gasolio,
  SuperSp,
  GPL,
  GasolioAdd,
  SuperSpAdd,
  NonOil,
  AdBlue,
  GNL,
}

export const productsColors = [
  'light',
  'danger',
  'warning',
  'success',
  'secondary',
  'light',
  'dark',
  'primary',
  'info'
]
