import {Role} from "../enum/role";
import jwtDecode from "jwt-decode";
import {Plant} from "./plant";

export class User {

  idUser: number;
  level: Role;
  username: string;
  protected _exp: number;
  protected iat: number;
  token: string;
  refreshToken: string;
  plants: number|number[] = [];
  plantsList: Plant[] = [];
  productsList: string[] = [];

  constructor(token: string, refreshToken: string) {

    let decoded: any = jwtDecode(token);

    this.idUser = decoded.idUser;
    this.level = decoded.level;
    this.username = decoded.username;
    this._exp = decoded.exp;
    this.iat = decoded.iat;
    this.token = token;
    this.refreshToken = refreshToken;
    this.plants = decoded.idPlant;
    this.plantsList = decoded.plants;
    this.productsList = decoded.products;
  }

  getRole(): Role {
    return this.level;
  }

  get exp() {
    return this._exp;
  }
}

export type Login = {
  message: string;
  cLevel: Role;
  idSes: number;
  idUserRoot: number;
  netId: number;
  userRefreshToken: string;
  userToken: string;
}

export type Refresh = {
  message: string;
  userRefreshToken: string;
  userToken: string;
}
