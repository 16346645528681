import {Component, Inject, Input, OnInit} from '@angular/core';
import {ClosuresService} from "../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {Formatter} from "../../../utils/formatter";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {ConfirmDialogComponent} from "../../../utils/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-closure-add',
  templateUrl: './closure-add.component.html',
  styleUrls: ['./closure-add.component.css']
})
export class ClosureAddComponent implements OnInit {

  @Input()
  type: Type = Type.IN;

  @Input()
  plantId: number = 0;

  @Input()
  period = {
    from: '',
    to: ''
  };

  title = '';
  subtitle = '';

  form: FormGroup;
  isModalView = false;
  loaded = new BehaviorSubject<boolean>(true);

  dates: string[] = [];

  formatDate = Formatter.formatDate;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private closureService: ClosuresService,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ClosureAddComponent>
  ) {

    if (modalData) {

      this.isModalView = true;

      if (modalData.plantId) {
        this.plantId = modalData.plantId;
      }

      if (modalData.subtitle) {
        this.subtitle = modalData.subtitle;
      }

      if (modalData.type) {
        this.type = modalData.type;
      }

      if (modalData.period) {
        this.period = modalData.period;
      }

    }

    this.form = this.fb.group({
      date: ['', Validators.required],
      close: [false]
    });

    switch (this.type) {
      case Type.IN:
        this.title = 'Acquisisci dati';
        break;
      case Type.OUT:
        this.title = 'Invia dati';
        break
    }
  }

  ngOnInit(): void {

    let from = new Date(this.period.from);
    let to = new Date(this.period.to);
    to.setDate(to.getDate() + 1);

    let params: any = {
      from: from,
      to: to,
      sort: 'date',
      order: 'asc',
      page: 1,
      pageSize: 999
    };

    this.closureService.plantList(this.plantId, params).subscribe((res) => {

      let formattedTo = Formatter.defaultFormatDate(to);

      this.logger.debug('Formatted to: ', formattedTo);

      for (let d = from; d < to; d.setDate(d.getDate() + 1)) {
        const formatted = Formatter.defaultFormatDate(d);
        this.logger.debug(formatted);

        if (formatted.day === formattedTo.day && formatted.month === formattedTo.month) {
          continue;
        }

        this.dates.push(`${formatted.year}-${formatted.month}-${formatted.day}`);
      }

      res.data.forEach((r) => {
        let date: string|Date = new Date(r.closure.dates.accounting);
        const formatted = Formatter.defaultFormatDate(date);
        date = `${formatted.year}-${formatted.month}-${formatted.day}`;

        this.dates = this.dates.filter((el) => {
          return el != date;
        });
      });
    });

  }

  alertClose(event: MatSlideToggleChange) {

    console.log(event.checked);

    if (event.checked) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Forza chiusura a zero',
          msg: 'Sei sicuro/a di voler chiudere la giornata con i valori a zero?',
          msg2: 'L\'operazione non sarà reversibile!',
          colorYes: 'warn',
          colorNo: 'primary'
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        event.checked = response;

        this.form.controls.close.setValue(response);
      })

    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.loaded.next(false);
      const data = this.form.value;

      this.logger.debug(data);

      this.closureService.add({
        type: this.type,
        plant_id: this.plantId ?? 0,
        date: data.date,
        close: data.close
      }).subscribe({
        next: (res: any) => {
          this.loaded.next(true);
          this.logger.debug(res);

          let emptyData = true;

          for (let key in res) {
            if (res[key] > 0) emptyData = false;
          }

          this.dialogRef.close({
            success: true,
            emptyData
          });
        },
        error: (error: any) => {
          this.logger.error(error);
          this.dialogRef.close({
            success: false
          });
        }
      });

    }
  }

}

enum Type {
  IN='in',
  OUT='out'
}
