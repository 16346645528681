import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import {
  Transaction,
  TransactionListResult,
} from "../../../models/transaction";
import {
  BehaviorSubject,
  catchError,
  map,
  merge,
  of,
  startWith,
  switchMap,
} from "rxjs";
import { Product } from "../../../models/product";
import { Service } from "../../../models/service";
import { PaymentType } from "../../../models/payment-type";
import { Formatter } from "../../utils/formatter";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TransactionsService } from "../../../services/transactions.service";
import { NGXLogger } from "ngx-logger";
import { productsColors } from "../../../enum/products";
import { Plant } from "../../../models/plant";
import { ExportService } from "../../../services/export.service";
import { FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelectModule } from "@angular/material/select";
import { DatePipe } from "@angular/common";

@Component({
  selector: "transaction-list",
  templateUrl: "./transaction-list.component.html",
  styleUrls: ["./transaction-list.component.css"],
})
export class TransactionListComponent implements AfterViewInit {
  loaded = new BehaviorSubject<boolean>(false);

  @Input()
  title = "";

  @Input()
  subtitle = "";

  @Input()
  displayedColumns: string[] = [];

  @Input()
  showFilter = false;

  @Input()
  showTotal = false;

  @Input()
  showComplessiveTotal = false;

  @Input()
  showPaging = false;

  @Input()
  sortEnabled = false;

  @Input()
  showExport = false;

  @Input()
  exportLimit = 10000;

  data: Transaction[] = [];
  resultsLength = 0;
  totalAmount = 0.0;
  totalQuantity = 0.0;
  isLoadingResults = true;
  isExportLoading = false;

  @Input()
  filter: any = {
    sort: "dateTime",
    order: "desc",
    page: 1,
    pageSize: 10,
  };
  filterChanged = new BehaviorSubject<boolean>(false);

  plantsObjects: Plant[] = [];
  productsObjects: Product[] = [];
  serviceTypesObjects: Service[] = [];
  paymentTypesObjects: PaymentType[] = [];

  formatAmount = Formatter.formatAmount;
  formatQuantity = Formatter.formatQuantity;
  formatPrice = Formatter.formatPrice;
  productsColors = productsColors;

  /** PER SELECT SEARCH PLANTS */
  /** controllo l'impianto selezionato */
  public plantCtrl: FormControl = new FormControl();

  /** controllo la parola chiave inserita nel filtro. */
  public plantFilterCtrl: FormControl = new FormControl();

  /** lista degli impianti filtrata per parola chiave di ricerca  */
  public filteredPlants: ReplaySubject<Plant[]> = new ReplaySubject<Plant[]>(1);

  protected _onDestroy = new Subject<void>();

  @ViewChild("singleSelect") singleSelect: MatSelectModule;
  /** FINE CAMPO SELECT SERARCH PLANTS */

  @ViewChild(MatPaginator) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatSort) sort: MatSort = Object.create(null);
  @ViewChild("input") input: ElementRef | undefined;
  constructor(
    private transactionService: TransactionsService,
    private logger: NGXLogger
  ) {
    let from = new Date();
    from.setDate(1);

    this.filter.from = from.toISOString();
  }

  ngAfterViewInit(): void {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filterChanged)
      .pipe(
        switchMap(() => {
          this.isLoadingResults = true;
          this.loaded.next(false);

          this.filter.sort = this.sort.active;
          this.filter.order = this.sort.direction;
          this.filter.page = this.paginator.pageIndex + 1;
          this.filter.pageSize = this.paginator.pageSize;

          this.logger.debug("Transaction list filters", this.filter);

          return this.transactionService.plantList(this.filter);
        }),
        map((data: TransactionListResult) => {
          this.logger.debug(data);

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.totals.rows;
          this.totalAmount = data.totals.amount;
          this.totalQuantity = data.totals.quantity;

          // Formatting plant list
          this.plantsObjects = [];
          data.meta.plants.forEach((plant) => {
            this.plantsObjects.push(plant);
          });

          // Formatting product list
          this.productsObjects = [];
          data.meta.products.forEach((product) => {
            this.productsObjects.push(product);
          });

          // Formatting service list
          this.serviceTypesObjects = [];
          data.meta.services.forEach((service) => {
            this.serviceTypesObjects.push(service);
          });

          // Formatting paymentTypes list
          this.paymentTypesObjects = [];
          data.meta.paymentTypes.forEach((paymentType) => {
            this.paymentTypesObjects.push(paymentType);
          });

          this.loaded.next(true);

          return data.data;
        }),
        catchError((err) => {
          this.isLoadingResults = false;
          this.logger.error(err);
          return of([]);
        })
      )
      .subscribe((data) => {
        this.logger.debug(data);
        this.data = data;

        // Carico la lista impianti iniziale
        this.filteredPlants.next(this.plantsObjects.slice());

        // Rimango in ascolto per il cambiamento del valore sul campo di input
        this.plantFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterPlants();
          });
      });
  }

  applyFilter(key: string, value: any) {
    if (value !== undefined && value !== null) {
      if (key === "plant" && value.length > 0) {
        this.filter[key] = value;
      } else {
        delete this.filter[key];
      }

      if ((key === "from" || key === "to") && value.toISOString().length > 0) {
        value = new Date(value);

        if (key === "from") {
          value.setHours(0, 0, 0);
        } else {
          value.setHours(23, 59, 59);
        }

        const datePipe = new DatePipe("en-US");
        const dataFormatted = datePipe.transform(
          value,
          "yyyy-MM-ddTHH:mm:ss.SSS"
        );

        this.filter[key] = dataFormatted;
      } else if (key === "from") {
        this.setDates("from");
      } else if (key === "to") {
        delete this.filter[key];
      }

      if (
        (key === "product" || key === "service" || key === "payment") &&
        !isNaN(value)
      ) {
        this.filter[key] = value;
      } else if (
        (key === "product" || key === "service" || key === "payment") &&
        isNaN(value)
      ) {
        delete this.filter[key];
      }
    } else {
      if (key === "from") {
        this.setDates("from");
      } else {
        delete this.filter[key];
      }
    }
  }

  filterData() {
    if (this.filter.to === undefined || this.filter.to < this.filter.from) {
      delete this.filter.to;
    }

    this.paginator.pageIndex = 0;
    this.filterChanged.next(true);
  }

  getPartialTotal(type: "amount" | "quantity") {
    let total: number = 0;

    this.data.forEach((el) => {
      switch (type) {
        case "amount":
          total += el.amount.total;
          break;
        case "quantity":
          total += el.quantity;
      }
    });

    switch (type) {
      case "amount":
        return this.formatAmount(total);
      case "quantity":
        return this.formatQuantity(total, {
          id: 1,
          name: "",
        });
    }

    return total;
  }

  formatDate(date: string) {
    let d = new Date(date);

    let formatted = Formatter.formatDate(d, true, "-", true);

    return (
      formatted +
      " - " +
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0")
    );
  }

  setDates(type: string = "") {
    if (type === "") {
      let to = new Date();
      let from = new Date();

      from.setDate(1);

      if (to.getDate() > 1) {
        to.setDate(to.getDate() - 1);
      }

      from.setHours(0, 0, 0);
      to.setHours(23, 59, 59);

      const datePipe = new DatePipe("en-US");
      const fromFormatted = datePipe.transform(from, "yyyy-MM-ddTHH:mm:ss.SSS");
      const toFormatted = datePipe.transform(to, "yyyy-MM-ddTHH:mm:ss.SSS");

      this.filter["from"] = fromFormatted;
      this.filter["to"] = toFormatted;
    } else if (type === "from") {
      let from = new Date();

      from.setDate(1);

      from.setHours(0, 0, 0);

      const datePipe = new DatePipe("en-US");
      const fromFormatted = datePipe.transform(from, "yyyy-MM-ddTHH:mm:ss.SSS");

      this.filter["from"] = fromFormatted;
    } else if (type === "to") {
      let to = new Date();

      if (to.getDate() > 1) {
        to.setDate(to.getDate() - 1);
      }

      to.setHours(23, 59, 59);

      const datePipe = new DatePipe("en-US");
      const toFormatted = datePipe.transform(to, "yyyy-MM-ddTHH:mm:ss.SSS");

      this.filter["to"] = toFormatted;
    }
  }

  exportAll() {
    const filter = { ...this.filter };
    filter.page = 1;
    filter.pageSize = this.exportLimit;
    this.isExportLoading = true;

    this.transactionService.plantList(filter).subscribe({
      next: (data) => {
        const exportData: ExportStruct[] = [];
        data.data.forEach((element: any) => {
          if (element.dateTime instanceof Date) {
            element.dateTime = element.dateTime.toISOString();
          }

          exportData.push({
            Data: Formatter.formatDate(element.dateTime, false, "-"),
            "Punto Vendita": element.plant.name,
            Tipologia: element.service.name,
            "N. Lato": element.dispenser.pump,
            "N. Erogatore": element.dispenser.pistols,
            Prodotto: element.product.name,
            "Quantita (Lt.)": element.quantity,
            "Prezzo unitario (EUR)":
              typeof element.unitPrice === "string"
                ? Number.parseFloat(element.unitPrice)
                : element.unitPrice,
            "Importo (EUR)": element.amount.total,
            "Metodo di pagamento": element.payment.name,
          });
        });

        ExportService.xlsx("transazioni", filter, exportData, true);
      },
      error: (e) => {
        this.logger.error(e);
        throw e;
      },
      complete: () => {
        this.isExportLoading = false;
      },
    });
  }

  /**  imposto i filtri per la select impianto con ricerca */
  protected filterPlants() {
    if (!this.plantsObjects) {
      return;
    }
    // ottengo la parola ricercata
    let search = this.plantFilterCtrl.value;
    if (!search) {
      this.filteredPlants.next(this.plantsObjects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filtro gli impianti
    this.filteredPlants.next(
      this.plantsObjects.filter(
        (plant) => plant.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  /** metodo per azzerare i valori della select impianti */
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

type ExportStruct = {
  Data: string;
  Tipologia: string;
  "Punto Vendita": string;
  "N. Lato": number;
  "N. Erogatore": number;
  Prodotto: string;
  "Quantita (Lt.)": number;
  "Prezzo unitario (EUR)": number;
  "Importo (EUR)": number;
  "Metodo di pagamento": string;
};
