import { style } from '@angular/animations';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ApexAxisChartSeries, ApexOptions, ApexNonAxisChartSeries,ApexPlotOptions,ApexChart,ApexFill,ChartComponent} from "ng-apexcharts";
import {BehaviorSubject, catchError, map, merge, of, startWith, switchMap} from "rxjs";
import {SalesService} from "../../../../services/sales.service";
import {NGXLogger} from "ngx-logger";
import {SalesTotalsByPlant, SalesTotalsByPlantResponse, SalesTotalsByProduct, SalesTotalsByProductResponse} from "../../../../models/sales-totals";
import {AuthenticationService} from "../../../../services/authentication.service";
import {Role} from "../../../../enum/role";
import { Formatter } from 'src/app/shared/utils/formatter';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: "app-dashboard-sales-pie",
  templateUrl: "./sales-pie.component.html",
  styleUrls: ["./sales-pie.component.css"],
})
export class DashboardSalesPieComponent implements OnInit {
  @ViewChild("sales-chart-donut") chart: ChartComponent = Object.create(null);
  public SalesChartDonutChartOptions: Partial<ApexOptions>;

  loaded = new BehaviorSubject<boolean>(false);

  @Input()
  title = "";

  @Input()
  period: SalesBarPeriod = SalesBarPeriod.WEEK;

  @Input()
  filters: {
    plant?: number | number[],
    product?: number,
    from: Date,
    to: Date
  } = {
    from: new Date(new Date().getTime() - 7),
    to: new Date()
  };

  type: 'plant' | 'product' = 'plant';
  
  filterChange = new BehaviorSubject<boolean>(false);

  periodList = [
    {
      id: SalesBarPeriod.WEEK,
      name: 'Ultima settimana'
    },
    {
      id: SalesBarPeriod.TWOWEEK,
      name: 'Ultime due settimane'
    },
    {
      id: SalesBarPeriod.MONTH,
      name: 'Ultimo mese'
    },
    {
      id: SalesBarPeriod.QUARTER,
      name: 'Ultimo trimestre'
    },
    {
      id: SalesBarPeriod.SEMESTER,
      name: 'Ultimo semestre'
    },
    {
      id: SalesBarPeriod.YEAR,
      name: 'Anno corrente'
    },
    {
      id: SalesBarPeriod.CUSTOM,
      name: 'Personalizzato'
    }
  ];


  constructor(
    private salesApi: SalesService,
    private logger: NGXLogger,
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.SalesChartDonutChartOptions = {
      series: [],
      chart: {
        type: "donut",
        fontFamily: "Poppins,sans-serif",        
        height: 345,
      },
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            labels: {
              show: true,
              value: {
                fontSize: "30px",
                formatter(val: string): string {
                  return (
                    new Intl.NumberFormat("it-IT", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(Number.parseFloat(val)) + " Lt."
                  );
                },
              },
              total: {
                show: true,
                // fontSize: "30px",
                label: "Totale",
                formatter(val: any): string {
                  return (
                    new Intl.NumberFormat("it-IT", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      Number.parseFloat(
                        val.globals.seriesTotals.reduce(
                          (a: number, b: number) => {
                            return a + b;
                          },
                          0
                        )
                      )
                    ) + " Lt."
                  );
                },
              },
            },
            size: "80px",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,        
      },
      noData: {
        text: "Nessun dato disponibile",
        style: { color: "#26c6da" },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      legend: {
        show: false,
      },
      labels: [],
      responsive: [
        {
          breakpoint: 767,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    };

    if (!this.userIsNet()) {
      this.type = 'product';   
    }
  }

  ngOnInit(): void {    
    merge(this.filterChange)
      .pipe(
        switchMap(() => {
          this.loaded.next(false);

          let dateOffset: number;

          switch (this.period) {
            case SalesBarPeriod.WEEK:
              dateOffset = 7;
              break;
            case SalesBarPeriod.TWOWEEK:
              dateOffset = 14;
              break;
            case SalesBarPeriod.MONTH:
              dateOffset = 30;
              break;
            case SalesBarPeriod.QUARTER:
              dateOffset = 90;
              break;
            case SalesBarPeriod.SEMESTER:
              dateOffset = 184;
              break;
            case SalesBarPeriod.CUSTOM:
              dateOffset = 0;
              break;
            default:
              dateOffset = -1;
              break;
          }
  
          if (dateOffset < 0) {
            this.filters.from = new Date((new Date()).getFullYear() + '-01-01');
          } else if (dateOffset > 0) {
            this.filters.from = new Date(new Date().setDate(this.filters.to.getDate() - dateOffset));
          } else {
            this.filters.from = new Date(this.filters.from);
            this.filters.to = new Date(this.filters.to);
          }

          const filters: {
            plant?: number | number[],
            product?: number,
            from: string,
            to: string
          } = {
            from: '',
            to: ''
          }

          filters.from = Formatter.formatFilterDate(this.filters.from);
          filters.to = Formatter.formatFilterDate(this.filters.to, true);

          if (typeof this.filters.product === 'number') {
            filters.product = this.filters.product;
          }
          
          if (typeof this.filters.product !== 'number') {
            delete this.filters.product;
            delete filters.product;
          }

          this.logger.debug("SalesPie filters: ", filters);

          if (this.type === 'plant') {
            return this.salesApi.getTotalsByPlant(filters);
          }

          return this.salesApi.getTotalsByProoduct(filters);
        }),
        map(
          (data: SalesTotalsByPlantResponse | SalesTotalsByProductResponse) => {
            this.logger.debug("Sales pie totals response data: ", data);
            data.data = this.sortDataByQuantity(data.data);

            const values: number[] = [];
            const labels: string[] = [];

            data.data.forEach((row: any) => {
              labels.push(
                this.type === 'plant' ? row.plant.name : row.product.name
              );
              values.push(row.quantity);
            });

            return {
              labels: labels,
              values: values,
            };
          }
        ),
        catchError(() => {
          this.loaded.next(false);
          return of([]);
        })
      )
      .subscribe((data: any) => {
        this.logger.debug("Sales pie totals formatted data: ", data);
        this.loaded.next(true);
        
        this.SalesChartDonutChartOptions.series = data.values;
        this.SalesChartDonutChartOptions.labels = data.labels;
      });
  }

  sortDataByQuantity(data: SalesTotalsByPlant[] | SalesTotalsByProduct[]) {
    return data.sort((a, b) => b.quantity - a.quantity);
  }


  setType(type: 'plant' | 'product'){
    this.type = type;
    this.filterChange.next(true);
  }

  applyFilters(key: string, value: any) {
    if (key === 'period' ) {
      this.period = value;      
    }

    if (key === 'from'){
      this.filters.from = value
    }

    if (key === 'to'){
      this.filters.to = value
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {periodList: this.periodList, period: this.period, dates: { from: this.filters.from, to: this.filters.to }},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {      
        if (result.period){
          this.applyFilters('period', result.period)

          if (result.period ===  SalesBarPeriod.CUSTOM) {
            this.applyFilters('from', result.dates.from);
            this.applyFilters('to', result.dates.to);
          }
        } else {        
          this.setBaseFilters();
        }        
        this.filterChange.next(true);
      }
    });
  }

  setBaseFilters() {
    this.period = SalesBarPeriod.WEEK;
    this.filters.from = new Date(new Date().getTime() - 7);
    this.filters.to = new Date();
  }

  userIsNet() {
    return this.authService.userValue ? this.authService.userValue.level === Role.NET : false;
  }
}


export enum SalesBarPeriod {
  WEEK,
  TWOWEEK,
  MONTH,
  QUARTER,
  SEMESTER,
  YEAR,
  CUSTOM
}
