<mat-card style="min-height: 90%">

  <mat-card-header *ngIf="title.length">
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="!loaded.value">
    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
  </mat-card-content>

  <mat-card-content *ngIf="loaded.value">
    <div class="table-responsive">
      <table mat-table [dataSource]="data">

        <!-- Service Column -->
        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef> Tipologia</th>
          <td mat-cell *matCellDef="let element">
            {{element.service.name}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef><strong>TOTALE</strong></td>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef> Prodotto</th>
          <td mat-cell *matCellDef="let element">
            {{element.product.name}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Quantit&agrave;</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatQuantity(element.quantity, element.product.id)}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}} text-end" mat-footer-cell *matFooterCellDef><strong>{{totalQuantity(totalQuantityValue)}}</strong></td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Prezzo unitario</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatPrice(element.unitPrice)}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Importo</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatAmount(element.amount)}}
          </td>
          <td class="{{showTotals ? '' : 'd-none'}} text-end" mat-footer-cell *matFooterCellDef><strong>{{totalAmount(totalAmountValue)}}</strong></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="{{showTotals ? '' : 'd-none'}}" mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="isModalView" class="mt-4 text-center">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Chiudi</button>
</div>

