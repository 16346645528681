import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map } from "rxjs";
import {
  SalesTotals,
  SalesTotalsByMonth,
  SalesTotalsByMonthResponse,
  SalesTotalsByPlantResponse,
  SalesTotalsByProductDateResponse,
  SalesTotalsByProductResponse,
  SalesTotalsResponse,
} from "../models/sales-totals";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  protected endpoint: string;

  constructor(protected http: HttpClient) {
    this.endpoint = `${environment.api}/sales`;
  }

  getTotals(params: any = {}) {
    return this.http
      .get(`${this.endpoint}/totals`, {
        params: params,
      })
      .pipe(map((res) => <SalesTotalsResponse>res));
  }

  getTotalsByMonth(params: any = {}) {
    params["group"] = "month";

    return this.http
      .get(`${this.endpoint}/totals`, {
        params: params,
      })
      .pipe(map((res) => <SalesTotalsByMonthResponse>res));
  }

  getTotalsByPlant(params: any = {}) {
    params["group"] = "plant";

    return this.http
      .get(`${this.endpoint}/totals`, {
        params: params,
      })
      .pipe(map((res) => <SalesTotalsByPlantResponse>res));
  }

  getTotalsByProoduct(params: any = {}) {
    params["group"] = "product";

    return this.http
      .get(`${this.endpoint}/totals`, {
        params: params,
      })
      .pipe(map((res) => <SalesTotalsByProductResponse>res));
  }

  getTotalsByProductAndDate(params: any = {}) {
    params["group"] = "product-date";

    return this.http
      .get(`${this.endpoint}/totals`, {
        params: params,
      })
      .pipe(
        map((res) => {
          return <SalesTotalsByProductDateResponse>res;
        })
      );
  }
}
