<mat-card>
  <mat-card-header *ngIf="title.length || subtitle.length" class="text-center w-100">
    <mat-card-title *ngIf="title.length">{{title}}</mat-card-title>
    <mat-card-subtitle *ngIf="subtitle.length">{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-dialog-content>
      <p>{{msg}}</p>

      <div *ngIf="msg2.length">
        {{msg2}}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button color="{{colorNo}}" [mat-dialog-close]="false">No</button>
      <button mat-button color="{{colorYes}}" [mat-dialog-close]="true">Si</button>
    </mat-dialog-actions>
  </mat-card-content>
</mat-card>
