<mat-card class="bg-info">
  <mat-card-content>
    <!-- column -->
    <div class="d-flex no-block align-items-center">
      <div class="stats">
        <h6 class="text-white m-0">Totale complessivo</h6>
        <h3 class="text-white m-0">{{formatTotal()}}</h3>
      </div>
      <div class="totals-graph-container ms-auto">
        <div class="d-flex flex-column">
          <apx-chart
            [series]="inexpu2chartOptions.series"
            [chart]="inexpu2chartOptions.chart"
            [dataLabels]="inexpu2chartOptions.dataLabels"
            [plotOptions]="inexpu2chartOptions.plotOptions"
            [yaxis]="inexpu2chartOptions.yaxis"
            [legend]="inexpu2chartOptions.legend"
            [fill]="inexpu2chartOptions.fill"
            [stroke]="inexpu2chartOptions.stroke"
            [tooltip]="inexpu2chartOptions.tooltip"
            [xaxis]="inexpu2chartOptions.xaxis"
            [grid]="inexpu2chartOptions.grid"
          >
          </apx-chart>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
