import { Component } from '@angular/core';
import {Formatter} from "../../../utils/formatter";
import {AbstractTotalComponent} from "../abstract-total.component";

@Component({
  selector: 'app-total-quantity',
  templateUrl: './total-quantity.component.html',
  styleUrls: ['./total-quantity.component.css']
})
export class TotalQuantityComponent extends AbstractTotalComponent{

  constructor() {super() }

  ngOnInit(): void {
  }

  formatTotal(): string {
    return Formatter.formatQuantity(this.total, {id: 1, name: ''}, 0);
  }

}
