import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  msg: string = '';
  msg2: string = '';
  title: string = '';
  subtitle: string = '';

  colorYes = 'primary';
  colorNo = 'warn';

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    if (modalData) {

      if (modalData.title) {
        this.title = modalData.title;
      }

      if (modalData.subtitle) {
        this.subtitle = modalData.subtitle;
      }

      if (modalData.msg) {
        this.msg = modalData.msg;
      }

      if (modalData.msg2) {
        this.msg2 = modalData.msg2;
      }

      if (modalData.colorYes) {
        this.colorYes = modalData.colorYes;
      }

      if (modalData.colorNo) {
        this.colorNo = modalData.colorNo;
      }

    }

  }

  ngOnInit(): void {
  }

}
