<div *ngIf="!loaded.value">

  <ngx-skeleton-loader *ngIf="showFilter" count="1" [theme]="{
    'border-radius': '5px',
    'width': '100%',
    'height': '200px',
    'margin-bottom': '2%'
    }" appearance="circle"></ngx-skeleton-loader>

  <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="showProgress.value"></mat-progress-bar>
<div class="{{loaded.value ? '' : 'd-none'}}">

  <!-- Filter -->
  <div *ngIf="showFilter" class="bg-light overflow-auto">

    <div class="d-flex filters">

      <mat-form-field class="m-5">
        <mat-label>Da</mat-label>
        <input matInput [matDatepicker]="pickerFrom" (dateChange)="applyFilter('from', $event.value)"
               [ngModel]="filter.from">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="m-5">
        <mat-label>A</mat-label>
        <input matInput [matDatepicker]="pickerTo" (dateChange)="applyFilter('to', $event.value)"
               [ngModel]="filter.to">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>

      <button mat-raised-button class="bg-primary text-white d-block filterButton" style="height: 50px; box-shadow: 2px 2px 6px -4px;" (click)="filterData()">
          <mat-icon fontIcon="restore"></mat-icon>
          &nbsp;
          Filtra
      </button>

      <div class="d-block" style="width: 20px;"></div>
    </div>

  </div>

  <!-- Data -->
  <div class="table-responsive">
    <table mat-table matSort [dataSource]="data" class="w-100" matSortActive="'date'" [matSortDisableClear]
           [matSortDirection]="'desc'">

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Data Contabile</th>
        <td mat-cell *matCellDef="let element">{{formatDate(element.dates.accounting, true)}}</td>
      </ng-container>

      <!-- Period Column -->
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Periodo di riferimento</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          {{formatDate(element.dates.period.start, false, '-', false, true)}} / <br> {{formatDate(element.dates.period.end, false, '-', false, true)}}
        </td>
      </ng-container>

      <!-- Sales Column -->
      <ng-container matColumnDef="sales">
        <th mat-header-cell *matHeaderCellDef class="text-end"> Vendite</th>
        <td mat-cell *matCellDef="let element" class="text-end">
          {{element.totals.sales}}
          &nbsp;
          <i-tabler *ngIf="element.totals.sales > 0" class="text-primary" name="info-circle-filled" (click)="openDetails(element, detailsType.Sales)"/>
        </td>
      </ng-container>

      <!-- Cash Column -->
      <ng-container matColumnDef="cash">
        <th mat-header-cell *matHeaderCellDef class="text-end"> Incassi</th>
        <td mat-cell *matCellDef="let element" class="text-end">
          {{element.totals.cash}}
          &nbsp;
          <i-tabler *ngIf="element.totals.cash > 0" name="info-circle-filled" class="text-primary" (click)="openDetails(element, detailsType.Cash)"/>
        </td>
      </ng-container>

      <!-- Levels Column -->
      <ng-container matColumnDef="levels">
        <th mat-header-cell *matHeaderCellDef class="text-end"> Livelli</th>
        <td mat-cell *matCellDef="let element" class="text-end">
          {{element.totals.levels}}
          &nbsp;
          <i-tabler *ngIf="element.totals.levels > 0" name="info-circle-filled" class="text-primary" (click)="openDetails(element, detailsType.Levels)"/>
        </td>
      </ng-container>

      <!-- Totalizer Column -->
      <ng-container matColumnDef="totalizers">
        <th mat-header-cell *matHeaderCellDef class="text-end"> Totalizzatori</th>
        <td mat-cell *matCellDef="let element" class="text-end">
          {{element.totals.totalizer}}
          &nbsp;
          <i-tabler *ngIf="element.totals.totalizer > 0" name="info-circle-filled" class="text-primary" (click)="openDetails(element, detailsType.Totalizers)"/>
        </td>
      </ng-container>

      <!-- Transactions Column -->
      <ng-container matColumnDef="transactions">
        <th mat-header-cell *matHeaderCellDef class="text-end"> Transazioni</th>
        <td mat-cell *matCellDef="let element" class="text-end">
          {{element.totals.transactions}}
        </td>
      </ng-container>

      <!-- Transmissions Column -->
      <ng-container matColumnDef="transmissions">
        <th mat-header-cell *matHeaderCellDef class="text-end"> Trasmissioni</th>
        <td mat-cell *matCellDef="let element" class="text-end">
          {{element.transmissions.total}}
          <span *ngIf="element.transmissions.missing.length">
            &nbsp;
            <i-tabler
              matTooltip="{{getMissingTransmissionRow(element.transmissions)}}"
              name="alert-triangle-filled"
              class="text-warning"/>
          </span>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <i-tabler 
            *ngIf="element.transmissions.missing.length" 
            matTooltip="Forza la trasmissione dei dati" 
            (click)="addTransmission(element.dates.accounting)" 
            class="text-info"
            name="brand-telegram"/>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator
    class="{{showPaging ? '' : 'd-none'}}"
    [length]="resultsLength"
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons>
  </mat-paginator>

  <div fxLayout="row wrap" *ngIf="showExport">
    <div fxFlex.gt-md="100" class="text-end">
      <div class="d-flex justify-content-end mt-5">
        <mat-spinner class="mt-2 ms-1" *ngIf="isExportLoading" [diameter]="40"></mat-spinner>
        <button class="btn btn-success m-2" (click)="exportAll()">
          <i-tabler name="file-scissors" class=""/>
          Esporta
        </button>
      </div>
      <i class="mt-1 text-end">
        *Dimensione massima esportazione: {{exportLimit}} giorni
      </i>
    </div>
  </div>

</div>
