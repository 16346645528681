<div class="bg-white position-absolute top-0 start-0 w-100 px-3 pt-4 pb-3 overflow-hidden">
  <div>
    <div class="row">
      <div class="d-flex">
        <h4 class="col-lg-6 page-title m-0 f-s-18 f-w-500 pt-2 text-primary">
          {{ pageInfo?.['title'] }}
        </h4>
        <div
          class="col-lg-6 align-items-center overflow-hidden justify-content-start list-style-none m-t-8 text-right"
        >
          <div class="d-inline-flex">
            <ng-template
              ngFor
              let-url
              [ngForOf]="pageInfo?.['urls']"
              let-last="last"
            >
              <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                <a [routerLink]="url.url" class="text-primary">{{ url.title }}</a>
              </li>
              <li class="active" style="margin-left: 20px;" *ngIf="last">{{ url.title }}</li>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumbSpace" style="padding-top: 50px; padding-bottom: 18px;"></div>
