import {Component, Inject, OnInit} from '@angular/core';
import {AbstractClosureDetailsComponent} from "../abstract-closure-details-component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ClosuresService} from "../../../../../services/closures.service";
import {NGXLogger} from "ngx-logger";
import {ClosureDetailsType} from "../../../../../enum/closure-details-type";
import {ClosureDetail} from "../../../../../models/closures";
import {Level} from "../../../../../models/level";
import {Totalizer} from "../../../../../models/totalizer";

@Component({
  selector: 'app-totalizers-list',
  templateUrl: './totalizers-list.component.html',
  styleUrls: ['./totalizers-list.component.css']
})
export class TotalizersListComponent extends AbstractClosureDetailsComponent{

  displayedColumns = ['pump', 'pistol', 'product', 'start', 'end', 'diff'];

  data: Totalizer[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    protected closureService: ClosuresService,
    protected logger: NGXLogger
  ) {
    super(modalData,closureService, logger)
  }

  ngOnInit() {

    this.filter = {
      type: ClosureDetailsType.Totalizers
    };

    this.fetchData((res: ClosureDetail) => {
      this.data = res.totalizers;
      this.data.sort(( a,  b) => a.dispenser.pump - b.dispenser.pump || a.dispenser.pistol - b.dispenser.pistol || a.product.id - b.product.id)
    });
  }

  formatNumber(number: number) {
    return new Intl.NumberFormat('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  }

}
