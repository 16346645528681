<mat-card>

  <mat-card-header *ngIf="title.length">
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
  </mat-card-header>


  <mat-card-content *ngIf="!loaded.value">

    <ngx-skeleton-loader *ngIf="showFilter" count="1" [theme]="{
    'border-radius': '5px',
    'width': '100%',
    'height': '200px',
    'margin-bottom': '2%'
    }" appearance="circle"></ngx-skeleton-loader>

    <ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader>

  </mat-card-content>

  <mat-card-content class="{{loaded.value ? '' : 'd-none'}}">

    <!-- Filter -->
    <div *ngIf="showFilter" class="bg-light overflow-auto m-b-30">

      <div class="d-flex filters ">
        <mat-form-field class="m-3" *ngIf="plantsObjects.length > 0">
          <mat-select 
            [formControl]="plantCtrl" 
            name="plant" 
            (selectionChange)="applyFilter('plant', $event.value)" 
            placeholder="Punti vendita" 
            #singleSelect  
            [ngModel]="filter.plant" 
            multiple>
              <mat-option>
                <ngx-mat-select-search 
                  placeholderLabel="Cerca..." 
                  [formControl]="plantFilterCtrl"
                  noEntriesFoundLabel="Nessun risultato.">
                </ngx-mat-select-search>
              </mat-option>

              <mat-option *ngFor="let plant of filteredPlants | async" [value]="plant.id">
                {{plant.name}}
              </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="m-3">
          <mat-select (selectionChange)="applyFilter('product', $event.value)" placeholder="Prodotto" [ngModel]="filter.product">
            <mat-option [value]="">Tutti</mat-option>
            <mat-option *ngFor="let prod of productsObjects" [value]="prod.id">
              {{prod.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="m-3">
          <mat-select (selectionChange)="applyFilter('service', $event.value)" placeholder="Servizio" [ngModel]="filter.service">
            <mat-option [value]="">Tutti</mat-option>
            <mat-option *ngFor="let serv of serviceTypesObjects" [value]="serv.id">
              {{serv.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="m-3">
          <mat-select (selectionChange)="applyFilter('payment', $event.value)" placeholder="Metodo di pagamento" [ngModel]="filter.payment">
            <mat-option [value]="">Tutti</mat-option>
            <mat-option *ngFor="let payment of paymentTypesObjects" [value]="payment.id">
              {{payment.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="m-3 datafilter">
          <mat-label>Da</mat-label>
          <input matInput [matDatepicker]="pickerFrom" (dateChange)="applyFilter('from', $event.value)" [ngModel]="filter.from">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="m-3 datafilter">
          <mat-label>A</mat-label>
          <input matInput [matDatepicker]="pickerTo" (dateChange)="applyFilter('to', $event.value)" [ngModel]="filter.to">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

        <button mat-raised-button class="bg-primary text-white d-block filterButton" style="box-shadow: 2px 2px 6px -4px; height: 50px;" (click)="filterData()">
          <mat-icon fontIcon="restore"></mat-icon>
          &nbsp;
          Filtra
        </button>
      </div>      
    </div>

    <!-- Data -->
    <div class="table-responsive">
      <table mat-table matSort
             [dataSource]="data"
             matSortActive="'dateTime'"
             [matSortDisableClear]
             [matSortDirection]="'desc'"
             class="w-100"
      >

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="dateTime" sortActionDescription="Ordina per data">Data</th>
          <td mat-cell *matCellDef="let element">{{formatDate(element.dateTime)}}</td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef><strong>TOTALE PARZIALE</strong></td>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="product" sortActionDescription="Ordina per prodotto"> Prodotto</th>
          <td mat-cell *matCellDef="let element">
            <button class="btn btn-sm btn-{{productsColors[element.product.id]}}" style="width: 100%; max-width: 100px">
              {{element.product.name}}
            </button>
          </td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Plant Column -->
        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef> Punto Vendita</th>
          <td mat-cell *matCellDef="let element"><a>{{element.plant.name}}</a></td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Quantit&agrave;</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatQuantity(element.quantity, element.product)}}
          </td>
          <td class="{{showTotal ? '' : 'd-none'}} text-end" mat-footer-cell *matFooterCellDef>
            <strong>{{ getPartialTotal('quantity') }}</strong>
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Prezzo unitario</th>
          <td mat-cell *matCellDef="let element" class="text-end">
            {{formatPrice(element.unitPrice)}}
          </td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Payment Column -->
        <ng-container matColumnDef="payment">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Metodo di pagamento</th>
          <td mat-cell *matCellDef="let element" class="text-end">{{element.payment.name}}</td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Pump Column -->
        <ng-container matColumnDef="pump">
          <th mat-header-cell *matHeaderCellDef> N. Lato</th>
          <td mat-cell *matCellDef="let element">
            {{element.dispenser.pump}}
          </td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Pistol Column -->
        <ng-container matColumnDef="pistol">
          <th mat-header-cell *matHeaderCellDef> N. Erogatore</th>
          <td mat-cell *matCellDef="let element">
            {{element.dispenser.pistols}}
          </td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Service Column -->
        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef> Tipologia</th>
          <td mat-cell *matCellDef="let element"> {{ element.service.name}} </td>
          <td class="{{showTotal ? '' : 'd-none'}}" mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="text-end"> Importo</th>
          <td mat-cell *matCellDef="let element" class="text-end"> {{formatAmount(element.amount.total)}} </td>
          <td class="{{showTotal ? '' : 'd-none'}} text-end" mat-footer-cell *matFooterCellDef><strong>{{ getPartialTotal('amount') }}</strong></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="{{showTotal ? '' : 'd-none'}}" mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator class="{{showPaging ? '' : 'd-none'}}"
      [length]="resultsLength"
      [pageSizeOptions]="[10, 25, 50]"
      showFirstLastButtons>
    </mat-paginator>


    <div *ngIf="showComplessiveTotal" class="totalBox">
      <div fxFlex.gt-md="40" fxFlex.gt-lg="25" fxFlex.gt-md="100" class="totalAmount">
        <app-total-amount [total]="totalAmount"></app-total-amount>
      </div>
      <div fxFlex.gt-md="40" fxFlex.gt-lg="25" fxFlex.gt-md="100">
        <app-total-quantity [total]="totalQuantity"></app-total-quantity>
      </div>
      <div *ngIf="showExport" fxFlex.gt-xs="100" fxFlex.gt-md="40" fxFlex.gt-lg="48" class="text-end">
        <div class="d-flex justify-content-end mt-5">
          <mat-spinner class="mt-2 ms-1" *ngIf="isExportLoading" [diameter]="40"></mat-spinner>
          <button class="btn btn-success m-2" (click)="exportAll()">
            <i-tabler name="file-scissors"/>
            Esporta
          </button>
        </div>
        <i class="mt-1 text-end">
          *Dimensione massima esportazione: {{exportLimit}} righe
        </i>
      </div>
    </div>

  </mat-card-content>



</mat-card>
