export enum Months {
  Gen= 1,
  Feb,
  Mar,
  Apr,
  Mag,
  Giu,
  Lug,
  Ago,
  Set,
  Ott,
  Nov,
  Dic
}

export const getMonthName = (month: Months) => {

  switch (month) {
    case Months.Gen:
      return 'Gennaio';
    case Months.Feb:
      return 'Febbraio';
    case Months.Mar:
      return 'Marzo';
    case Months.Apr:
      return 'Aprile';
    case Months.Mag:
      return 'Maggio';
    case Months.Giu:
      return 'Giugno';
    case Months.Lug:
      return 'Luglio';
    case Months.Ago:
      return 'Agosto';
    case Months.Set:
      return 'Settembre';
    case Months.Ott:
      return 'Ottobre';
    case Months.Nov:
      return 'Novembre';
    case Months.Dic:
      return 'Dicembre';
    default:
      return '?';
  }

}

export const getMonthsNames = () => {
  return [
    getMonthName(Months.Gen),
    getMonthName(Months.Feb),
    getMonthName(Months.Mar),
    getMonthName(Months.Apr),
    getMonthName(Months.Mag),
    getMonthName(Months.Giu),
    getMonthName(Months.Lug),
    getMonthName(Months.Ago),
    getMonthName(Months.Set),
    getMonthName(Months.Ott),
    getMonthName(Months.Nov),
    getMonthName(Months.Dic),
  ]
}
